@import url('https://fonts.googleapis.com/css2?family=Reddit+Sans:ital,wght@0,200..900;1,200..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Reddit Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
